export default {
    defaultPath: '/dashboard/default',
    basename: '', // only at build time to set, like /datta-able
    // layout: 'vertical', // vertical, horizontal
    // preLayout: 'layout-6', // null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
    // collapseMenu: 'mini-menu', // mini-menu, false
    // layoutType: 'menu-dark', // menu-dark, menu-light, dark
    // navIconColor: true,
    // headerBackColor: 'header-lightblue', // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
    // navBackColor: 'navbar-default', // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
    // navBrandColor: 'brand-lightblue', // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
    // navBackImage: true, // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
    // rtlLayout: false,
    // navFixedLayout: false,
    // headerFixedLayout: false,
    // boxLayout: false,
    // navDropdownIcon: 'style2', // style1, style2, style3
    // navListIcon: 'style3', // style1, style2, style3, style4, style5, style6
    // navActiveListColor: 'active-blue', // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
    // navListTitleColor: 'title-default', // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
    // navListTitleHide: false,
    // configBlock: false,
    layout6Background: 'linear-gradient(to right, #3f4d67 0%, #3f5172 52%, #23b7e5 100%)', // used only for pre-layout = layout-6
    layout6BackSize: '', // used only for pre-layout = layout-6
    layout: 'horizontal',
    preLayout: null,
    collapseMenu: false,
    layoutType: 'menu-light',
    navIconColor: true,
    headerBackColor: 'header-blue',
    navBackColor: 'navbar-default',
    navBrandColor: 'brand-default',
    navBackImage: false,
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: true,
    boxLayout: false,
    navDropdownIcon: 'style1',
    navListIcon: 'style1',
    navActiveListColor: 'active-default',
    navListTitleColor: 'title-default',
    navListTitleHide: false,
    configBlock: false,
};

//fons ghayar

