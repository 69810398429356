import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import configApi from '../configApi';
import routes from "../route";
import * as axios from "axios";


const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            auth: false,
            loading: true
        };
    }


    checkLoginStatus() {
        // const pathname = this.props.location;
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(process.env.REACT_APP_PUBLIC_URL + 'check_auth', {}, configApi);
                if (response.data.result === true) {
                    this.setState({ 'auth': true });
                    if (!this.state.auth)
                        return <Redirect to='/login' />;
                    // this.props.history.push(`/login`);
                }
                resolve(response.data);
            } catch (error) {
                this.setState({ 'auth': false });
                // 
                // this.props.history.push(`/login`);
                // return <Redirect to='/login'/>
                resolve('error');
            }
        });
    }

    async componentDidMount() {
        await this.checkLoginStatus();
        demoAsyncCall().then(() => this.setState({ loading: false }));


    }



    render() {
        const { loading } = this.state;
        if (loading) { // if your component doesn't have to wait for an async action, remove this block
            return null; // render null when app is not ready

        }
        if (!this.state.auth && (window.location.pathname !== "/login" && window.location.pathname !== "/reset-password"))
            return <Redirect to='/login' />;
        const menu = routes.map((route, index) => {
            return (route.component) ? (
                <Route key={index} path={route.path} exact={route.exact} name={route.name} render={props => (
                    <route.component {...props} />
                )} />
            ) : (null);

        });

        return (

            <Aux>

                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            {menu}
                            <Route path="/" component={AdminLayout} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}
function demoAsyncCall() {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
}

export default App;
