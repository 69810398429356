import React from 'react';


const NewLogin = React.lazy(() =>
    import ('./Application/Auth/login/Login'));
const ResetPassword = React.lazy(() =>
    import ('./Application/Auth/login/ResetPassword'));


const route = [

    { path: '/login', exact: true, name: 'Login', component: NewLogin },
    { path: '/reset-password', exact: true, name: 'Reset Password', component: ResetPassword },


];

export default route;